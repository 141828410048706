* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0f7f5;
    font-family: 'Inter', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.careers_col {
    background-image: url(./../src/img/careers.jpg);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
    height: 370px;
}

[type="search"] {
    border: 1px solid #AAA;
    font-size: 1rem;
    margin-block: 0.5rem;
    padding: 0.5rem 0.75rem;
}

[type="search"]::-webkit-search-cancel-button {
    --reset: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.016 15.609l-3.609-3.609 3.609-3.609-1.406-1.406-3.609 3.609-3.609-3.609-1.406 1.406 3.609 3.609-3.609 3.609 1.406 1.406 3.609-3.609 3.609 3.609zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z"/></svg>');
    background-color: currentColor;
    display: block;
    height: 1rem;
    mask-image: var(--reset);
    width: 1rem;
    -webkit-mask-image: var(--reset);
}

.fa option {
    font-weight: 900;
}

.dfsdgfert {
    margin: auto;
    height: 350px;
    width: 900px;
    display: flex;
    align-items: center;
}


.business_col {
    width: 457px;
    background: white;
    padding: 24px;
}

.text_col {
    color: #2770c4;
    font-weight: 700;
    font-size: 24px;
}

.bg-blue-medium {
    background-color: #2770c4;
    padding: 16px !important;
}

@media (min-width: 62em) {
    .css-1dlghxq {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        display: block;
        margin-block-start: 2.5rem;
        padding: var(--bds-spacing-24);
        width: 990px !important;
    }
}

.text-starts{
    font-size: 22px;
    font-weight: 400;
}

/* .css-1dlghxq {
    display: none;
} */

@media (prefers-reduced-motion: reduce) {

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}


.visually-hidden {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

@media (min-width: 62em) {
    .css-1dlghxq .epsahlr0 {
        display: flex;
        gap: 40px;
        justify-content: center;
    }
}

@media (min-width: 62em) {
    .css-1dlghxq .e1wzch7s0 {
        border: 0;
        box-shadow: var(--bds-shadow-inner);
    }
}

.css-wmunhi {
    align-items: stretch;
    background: var(--bds-color-white);
    border: 1px solid var(--bds-color-blue-brand);
    display: flex;
    justify-content: space-between;
    line-height: var(--bds-font-leading-1);
    position: relative;
    width: 100%;
    border: 1px solid var(--bds-color-gray-50);
    min-height: 2.1875rem;
}

.css-1nm0rrn {
    align-items: center;
    display: flex;
    font-weight: var(--bds-font-weight-bold);
    flex: 0 0 auto;
    margin: 0;
    padding: 0 0 0 12px;
    z-index: 1;
    font-weight: var(--bds-font-weight-bold);
    padding-right: 0;
}

.css-1ci0kms {
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    margin-left: 12px;
    width: 100%;
}

.css-wmunhi input,
.css-wmunhi input:focus {
    border: none;
    background-color: var(--bds-color-white) !important;
}

.css-d0ontt {
    padding: 0;
    width: 100%;
    outline: none;
}

@media (min-width: 62em) {
    .css-14232aq {
        max-height: none;
    }
}

.css-14232aq {
    background-color: var(--bds-color-white);
    background-clip: padding-box;
    box-shadow: var(--bds-shadow-base);
    display: none;
    left: 0;
    max-height: 200px;
    min-width: 285px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 999;
}


@media (prefers-reduced-motion: reduce) {

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}


.css-1uunpja {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
}

@media (prefers-reduced-motion: reduce) {

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

.css-wmunhi {
    align-items: center;
    background: var(--bds-color-white);
    border: 1px solid var(--bds-color-blue-brand);
    display: flex;
    justify-content: space-between;
    line-height: var(--bds-font-leading-1);
    position: relative;
    width: 100%;
    border: 1px solid var(--bds-color-gray-50);
    min-height: 2.1875rem;
    background-color: #fff;
}


.css-1nm0rrn {
    align-items: center;
    display: flex;
    font-weight: var(--bds-font-weight-bold);
    flex: 0 0 auto;
    margin: 0px 10px 0px 0px;
    padding: 0 0 0 12px;
    z-index: 1;
    font-weight: var(--bds-font-weight-bold);
    padding-right: 0;
}

@media (prefers-reduced-motion: reduce) {

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

.css-1ci0kms {
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    margin-left: 12px;
    width: 100%;
}

.css-wmunhi input,
.css-wmunhi input:focus {
    border: none;
    background-color: var(--bds-color-white) !important;
}

.css-d0ontt {
    padding: 0;
    width: 100%;
    outline: none;
}

@media (min-width: 62em) {
    .css-14232aq {
        max-height: none;
    }
}

.css-14232aq {
    background-color: var(--bds-color-white);
    background-clip: padding-box;
    box-shadow: var(--bds-shadow-base);
    display: none;
    left: 0;
    max-height: 200px;
    min-width: 285px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 999;
}

.css-1uunpja {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
}

.text-size-7 {
    font-size: .9375rem;
}

.visually-hidden {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.css-1xdgpde .e7isygd0>[role='combobox'] {
    height: 100%;
}

.css-mv0idm>[role='combobox'] {
    align-items: center;
    border: none;
    border-left: 1px solid var(--bds-color-gray-40);
    cursor: pointer;
    display: flex;
    height: 100%;
    gap: 0.25rem;
    padding: 0.5rem;
}

.text-blue-dark {
    color: #2770c4;
}

.bg-gray-30 {
    background-color: #f2f2f2;
}


.css-mv0idm>[role='listbox'] {
    margin-block: 0;
    position: absolute;
    right: 0;
    width: max-content;
    z-index: 1;
}

.shadow-base {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}

.bg-gray-30 {
    background-color: #f2f2f2;
}

.css-zyn7di {
    list-style: none;
    padding-left: 0;
}

[hidden] {
    display: none !important;
}

.css-mv0idm>[role='listbox']>[role='option'] {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 0.875rem;
}

.css-mv0idm>[role='listbox']>[role='option'] {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 0.875rem;
}

.bds-button[data-type=search] {
    --bg: var(--bds-color-orange-dark-85);
    --fg: var(--bds-color-black);
    --hover-border: var(--bds-color-orange-brown);
    --hover-bg: var(--bds-color-white);
    --hover-fg: var(--bds-color-orange-brown);
    text-shadow: 1px 1px 0 hsla(0, 0%, 100%, .21);
}

.bds-button:enabled {
    cursor: pointer;
}

.css-wbkz77.css-wbkz77 {
    align-self: stretch;
    flex-shrink: 0;
    min-width: 0;
    padding: 10px 17px 9px 23px;
}

.css-1xdgpde .epsahlr1,
.css-1xdgpde button {
    height: 100%;
}

.css-mv0idm {
    position: relative;
    height: 50px;
}

.visually-hidden {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.css-1xdgpde .e7isygd0>[role='combobox'] {
    height: 100%;
}

.css-mv0idm>[role='combobox'] {
    align-items: center;
    border: none;
    border-left: 1px solid var(--bds-color-gray-40);
    cursor: pointer;
    display: flex;
    height: 100%;
    gap: 0.25rem;
    padding: 0.5rem;
}

.text-blue-dark {
    color: #2770c4;
}

.bg-gray-30 {
    background-color: #f2f2f2;
}

.css-mv0idm>[role='combobox'] img {
    height: 0.8125rem;
    transform: translateY(0.1em);
    width: 1.0625rem;
}

.css-mv0idm>[role='combobox'] svg {
    width: 0.625em;
}

.css-1ukaq79 {
    fill: currentColor;
}

.css-mv0idm>[role='listbox'] {
    margin-block: 0;
    position: absolute;
    right: 0;
    width: max-content;
    z-index: 1;
}

.shadow-base {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}


.css-mv0idm>[role='listbox'] {
    margin-block: 0;
    position: absolute;
    right: 0;
    width: max-content;
    z-index: 1;
}

.shadow-base {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}

.bg-gray-30 {
    background-color: #f2f2f2;
}

.css-zyn7di {
    list-style: none;
    padding-left: 0;
}

.css-mv0idm>[role='listbox']>[role='option'] {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 0.875rem;
}

.css-mv0idm>[role='listbox']>[role='option'] img {
    height: 0.8125rem;
    transform: translateY(0.1em);
    width: 1.0625rem;
}

.css-1dlghxq .epsahlr0 {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
}

.dtm-header-search-submit {
    border: 0;
    color: #fff;
    font-weight: 700;
}

.logo_cols {
    width: 60px;
    object-fit: cover;
    object-position: center;
    height: 69px;
}


.form-select:focus {
    box-shadow: none !important;
}

.card-img-top {
    width: 280px;
    object-fit: contain;
    object-position: center;
    height: 260px;
}

a {
    text-decoration: underline;
}

.fgfrtyi {
    text-decoration: underline !important;
    color: #2770c4 !important;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.wrapData button {
    padding: 0px 8px 0px 8px;
    margin: 0px 8px 0px 8px;
}

.image_col img {
    width: 282px;
    object-fit: cover;
    object-position: center;
    height: 242px;
}

.text-start div p {
    font-size: 17px;
    margin: 0 !important;
    /* font-family: calibri; */
}

.text-start {
    font-size: 17px;
    /* font-family: calibri; */
}

.time {
    background: black;
    color: #fff;
    padding: 8px;
}

.about_col {
    height: 90vh;
}


.sdeoi {
    text-decoration: none !important;
}

.content {
    text-align: justify !important;
}

.items_colss::after {
    content: "";
    display: table;
    margin-top: 1px;
    width: 34px;
    background: #2770c4;
    color: #2770c4;
    height: 2px;
}

.css-b62m3t-container {
    border: none !important;
}

.header_cols {
    background-color: #2770c4;
    color: #fff;
    padding: 14px;
}

.fthyiop {
    height: 355px;
}

.login_cols {
    border: 1px solid darkgrey;
    background-color: #fff !important;
}

.form-label {
    font-size: 15px;
    font-weight: 500;
    color: #686464;
}

.form-check-label {
    font-size: 15px;
    font-weight: 500;
    color: #686464;
}

.heading_col {
    font-size: 15px;
    font-weight: 500;
    color: #686464;
}

.button_cols {
    font-size: 15px;
}

.google_col {
    color: #514e4e;
    font-size: 14px;
    border: 1px solid darkgrey;
    padding: 0px 8px 0px 8px;
}

.facabook_col {
    background-color: #2770c4 !important;
    color: #fff !important;
    font-size: 14px;
    border: 1px solid darkgrey;
    padding: 0px 16px 0px 16px;
}

.linkedin_col {
    background-color: #2770c4 !important;
    color: #fff !important;
    font-size: 14px;
    border: 1px solid darkgrey;
    padding: 0px 16px 0px 16px;
}

.amazon_col {
    background-color: #ffc107 !important;
    color: #514e4e !important;
    font-size: 14px;
    border: 1px solid darkgrey;
    padding: 0px 16px 0px 16px;
}

.windows_col {
    background-color: red !important;
    color: #fff !important;
    font-size: 14px;
    border: 1px solid darkgrey;
    padding: 0px 16px 0px 16px;
}

.help_col {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.text_colss {
    font-size: 14px;
    font-weight: 500;
    color: #686464;
}

.forgot_password {
    font-size: 14px;
}

.create_col {
    background-color: #0dadcd !important;
    color: #fff !important;
    font-size: 14px;
    border: none;
}

.contact_col {
    background-color: #2770c4 !important;
    color: #fff !important;
    font-size: 14px;
    border: none;
}

.nav-tabs {
    border: none;
}

#fill-tab-example-tab-home {
    border: none;
    padding: 12px;
    text-align: left;
    background-color: #2770c4;
    color: #fff !important;
    font-weight: 500;
    border-radius: 4px;
}

.header_colss {
    background-color: #fdfcfc !important;
    font-size: 15px;
    font-weight: 500;
    color: #686464;
}


.form_colss {
    background-color: #eae1e159 !important;
}

.login_colss {
    box-shadow: 0px 2px 6px 1px darkgrey;
    background-color: #fff !important;
}

.fa-map-marker {
    color: #2770c4;
}

.fa-map-marker-alt {
    color: #2770c4;
}

.fgfdhgt {
    font-size: 14px;
}

.fgfdhgty {
    font-size: 17px;
}

#fill-tab-example-tab-home {
    border: none;
    padding: 12px;
    text-align: left;
    background-color: #2770c4;
    color: #fff !important;
    font-weight: 500;
    border-radius: 4px;
    width: 250px;
}

#fill-tab-example-tab-profile {
    border: none;
    padding: 12px;
    text-align: left;
    background-color: #92b8e4;
    color: #fff !important;
    font-weight: 500;
    border-radius: 4px;
    margin: 0px 16px 0px 16px;
    width: 250px;
}

#fill-tab-example-tab-longer-tab {
    border: none;
    padding: 12px;
    text-align: left;
    background-color: #92b8e4;
    color: #fff !important;
    font-weight: 500;
    border-radius: 4px;
    margin: 0px 0px 0px 34px;
    width: 250px;
}

.nav-fill .nav-item {
    flex: none !important;
}

.contact_us {
    height: 100vh;
}

.about_us {
    height: 100vh;
}

.filters {
    font-size: 14px;
}

.form-check {
    width: '220px!important'
}

.sdert {
    border: 1px solid darkgray;
    padding: 3px 9px 5px 34px;
    background: white;
    border-radius: 4px;
    width: '60%!important'
}

.select_col {
    width: '220px!important'
}

.form_cols {
    background-color: #c2e7e2;
    padding: 8px;
}

.sdfryo {
    background-color: #ebe9e9;
    padding: 14px;
}

.sdfryo h5 {
    font-size: 14px;
}

.accredited {
    padding: 10px;
    background-color: #cdc8c8a1;
    box-shadow: 0px 1px 18px 0px darkgrey;
}


.remodeling {
    box-shadow: 0px 0px 15px 0px darkgrey;
    padding: 16px;
}

.remodeling a {
    text-decoration: none !important;
    color: inherit !important;
}

.categories_col a {
    color: #2770c4;
    font-weight: 600;
}

.categories_col a h6 {
    font-size: 14px;
}

.text-blue-brand {
    color: #2770c4;
    background: #a9a9a959;
    padding: 6px;
    border-radius: 4px;
    font-size: 30px;
    font-weight: 700;
}

.image_cols img {
    width: 59px;
    object-fit: contain;
    object-position: center;
    height: 49px;
}

.dffrtr p {
    font-size: 14px;
    font-weight: 600;
}

.fertrgyt {
    font-size: 24px;
    color: #2770c4;
    font-weight: 700 !important;
}

.business_cols {
    font-size: 14px;
    font-weight: 600;
}

.contect_col h3 {
    font-weight: 400;
}

.sdfryoy {
    background-color: #ebe9e9;
    padding: 28px;
}

.sdfryoy h5 {
    font-size: 20px;
    color: darkgray;
}

.remodeling_cols {
    background-color: #a6d4dd47;
    padding: 24px;
}

/* .remodeling_col{
    height: 100vh;
} */

.dfrty {
    height: 410px;
}

.fdgftyt_col p {
    color: #af272f;
    font-size: 15px;
}

.fdgftyt_col {
    text-decoration: none;
}

.fdgftyt_col:hover {
    text-decoration: underline;
    color: #af272f;
}

.css-b62m3t-container {
    width: 210px;
}

.dtm-header-near-typeahead {
    padding: 6px;
}

.sdfet_col {
    background-color: #ebf4f7;
    padding: 12px;
}

.customer_review {
    color: #2770c4;
}

.card_colss {
    height: 170px;
}

.card_cols {
    height: 300px;
}

.services_colss {
    color: #2770c4;
}

.list-group-item {
    border: none !important;
    padding: 2px;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-items: center;
    justify-content: space-between;
}

.nav-pills .nav-link.active {
    color: #fff !important;
    background-color: #2770c4 !important;
    padding: 8px 16px 8px 16px !important;
    /* width: 280px; */
    border-radius: 0 !important;
}


.search_colss .css-1dlghxq {
    margin: 0 !important;
    box-shadow: none !important;
}

.search_colss .bg-blue-medium {
    padding: 0 !important;
}

.search_colss {
    background-color: #2770c4;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 79px;
}

.form_colss p {
    font-size: 14px;
}

.sderti {
    font-size: 15px;
}

.button_colss {
    font-size: 15px;
    background-color: #2770c4;
}

.heading_col h6 {
    font-size: 15px;
    font-weight: 500;
}

.time {
    font-size: 14px;
}

.times {
    font-size: 14px;
}

.contact_cols {
    margin: auto;
    width: 750px;
}

.fa-square-x-twitter {
    color: #1974db;
    font-size: 30px;
}

.fa-google {
    color: #1974db;
    font-size: 30px;
}

.fa-facebook {
    color: #20c5e6;
    font-size: 30px;
}


.fa-instagram-square {
    color: rgb(250 72 4);
    font-size: 30px;
}

.btn_grtyui {
    background-color: #2770c4 !important;
}

.likes_col h2 span {
    border-bottom: 2px solid #ffc107;
}

.fgfh_col {
    text-decoration: none !important;
}

.dfrtyui {
    color: #0b3c46;
    font-size: 18px;
    line-height: 22px;
    word-spacing: -3px;
}

.share_cols {
    border-top: 2px solid darkgray;
}

.about_uss {
    background-color: #fff !important;
    padding: 10px;
}

.contact_uss {
    background-color: #2770c4;
    color: #fff;
}

.ghdtsrer {
    height: 100vh;
}

.header_col {
    background-color: #2770c4;
    color: #fff;
    font-weight: 600;
}

.fa-phone-alt {
    color: #2770c4;
}

.fa-clock {
    color: #2770c4;
}

.fa-language {
    color: #2770c4;
}

.login_colsss {
    margin: auto;
    width: 750px;
}

.img_colss {
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: 73px;
}

.review_colss {
    color: #2770c4;
}

.icons_cols i:hover {
    color: rgb(255, 145, 0);
}

.button_colsss {
    background-color: #2770c4;
    color: #fff;
    padding: 8px;
    font-weight: 600;
}

.right_col {
    background-color: #d7e7ea;
    padding: 10px;
}

.review_colss {
    color: #2770c4;
}

.header_colssi {
    padding: 25px;
    background-color: #ffc107;
    margin: 0 -2px 0px -12px;
    border-radius: 0 !important;
    border-bottom: none;
}

.border-0 {
    border-radius: 0 !important;
}

.forgot_password {
    text-decoration: none !important;
}

.contact_colssi {
    text-decoration: none !important;
    font-size: 14px !important;
}


.touch {
    color: #2770c4;
    font-weight: 600;
}

.imgss_colss {
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: 214px;
}

.imgss_colsio {
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: 330px;
}

.ghdtsrers {
    height: 80vh;
}

.btn-warning:hover {
    color: #fff !important;
}


.login_colsss2{
    height: 80vh;
}

@media screen and (max-width: 992px){
    .header_colssi {
        padding: 25px;
        background-color: #ffc107;
        margin: 0px -12px 0px -12px;
        border-radius: 0 !important;
        border-bottom: none;
    }

    .password_col i {
        position: absolute;
        bottom: 0;
        top: 0;
        transform: translate(651px, 12px)!important;
    }

    .login_colsss2 {
        height: 100vh;
    }

    .frtyuop{
        height: 80vh;
    }
}

@media screen and (max-width: 425px){
    .login_colss {
        width: 365px!important;
    }
    .login_colsss2 {
        height: 100vh;
    }
    .suggestions-list {
        left: 149px!important;
    }
}


@media screen and (max-width: 480px) {

    .text-starts a{
        font-size: 20px;
    }

    .login_colsss2 {
        height: 100vh;
    }

    .header_colssi {
        padding: 25px;
        background-color: #ffc107;
        margin: 0px -12px 0px -12px;
        border-radius: 0 !important;
        border-bottom: none;
    }
    
    .dfrty {
        height: 250px!important;
    }
    
    .login_colss {
        width: 405px;
      }


    .image_col .img {
        object-fit: cover;
        object-position: center;
        width: 355px;
    }

    .business_col {
        width: 367px;
        background: white;
        padding: 24px;
    }

    .bg-blue-medium {
        background-color: #2770c4;
        padding: 16px !important;
        width: 366px;
    }

    .css-1dlghxq .epsahlr0 {
        display: flex;
        gap: 40px;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap !important;
    }

    .dfsdgfert {
        margin: auto;
        height: 485px !important;
        width: 370px;
        display: flex;
        align-items: center;
    }

    .careers_col {
        height: 506px !important;
    }

    .login_colss {
        width: 395px;
    }

    .search_colss {
        background-color: #2770c4;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 370px !important;
    }

    .password_col i {
        position: absolute;
        /* left: 0; */
        bottom: 0;
        top: 0;
        /* right: 0; */
        transform: translate(340px, 12px) !important;
    }

    .card-img-top {
        width: 365px;
        object-fit: cover;
        object-position: center;
        height: 260px;
    }

    .imgss_colss{
        width: 330px;
    }


    .imgss_colsio{
        width: 330px;
    }

    .image_col img {
        width: 396px;
        object-fit: cover;
        object-position: center;
        height: 242px;
    }

    .fthyiop {
        height: 306px !important;
    }

    .frtyuop{
        height: 100vh;
    }

    #rswps-form {
        width: 323px!important;
    }
    @media screen and (max-width: 375px) {
        .password_col i {
            position: absolute;
            /* left: 0; */
            bottom: 0;
            top: 0;
            /* right: 0; */
            transform: translate(310px, 12px) !important;
        }

        .card-img-top {
            width: 279px;
            object-fit: cover;
            object-position: center;
            height: 260px;
        }

        .dfsdgfert {
            margin: auto;
            height: 485px !important;
            width: 360px;
            display: flex;
            align-items: center;
        }

        .login_colsss2 {
            height: 100vh;
        }
        
    }
}


@media screen and (max-width: 365px){
    .password_col i {
        position: absolute;
        /* left: 0; */
        bottom: 0;
        top: 0;
        /* right: 0; */
        transform: translate(300px, 12px) !important;
    }

    .login_colsss2 {
        height: 100vh;
    }
}



@media screen and (max-width: 375px) {
    .card-img-top {
        width: 316px;
        object-fit: cover;
        object-position: center;
        height: 260px;
    }

    .image_col .img {
        object-fit: cover;
        object-position: center;
        width: 335px;
    }

    .business_col {
        width: 347px;
        background: white;
        padding: 24px;
    }

    .bg-blue-medium {
        background-color: #2770c4;
        padding: 16px !important;
        width: 345px;
    }
    .header_colssi {
        padding: 25px;
        background-color: #ffc107;
        margin: 0 -11px 0px -11px!important;
        border-radius: 0 !important;
        border-bottom: none;
    }

    .login_colss {
        width: 345px!important;
      }

    .text_col {
        color: #2770c4;
        font-size: 22px;
        font-weight: 700;
    }
    .fthyiop {
        height: 306px;
    }

    .fertrgyt {
        font-size: 24px;
        color: #2770c4;
        font-weight: 700 !important;
    }

    .login_colsss2 {
        height: 100vh;
    }
}

@media screen and (max-width: 320px) {
    .card-img-top {
        width: 277px;
        object-fit: cover;
        object-position: center;
        height: 290px!important;
    }

    .business_col {
        width: 290px !important;
        background: white;
        padding: 24px;
    }

    .bg-blue-medium {
        background-color: #2770c4;
        padding: 16px !important;
        width: 290px !important;
    }

    .reports {
        font-size: 16px !important;
        padding: 0;
        margin: 0;
        margin-left: 0px;
        font-weight: 500;
    }

    .dfsdgfert {
        align-items: center;
        display: flex;
        height: 485px !important;
        margin: auto;
        width: 290px !important;
    }

    .card-img-top, .image_col img {
        object-fit: cover;
        object-position: center;
        width: 290px!important;
    }

    .imgss_colss{
        object-fit: cover;
        object-position: center;
        width: 265px!important;
    }

    .imgss_colsio{
        object-fit: cover;
        object-position: center;
        width: 265px!important;
    }

    .password_col i {
        bottom: 0;
        position: absolute;
        top: 0;
        transform: translate(260px, 12px) !important;
    }

    .header_colssi {
        padding: 25px;
        background-color: #ffc107;
        margin: 0 -11px 0px -11px!important;
        border-radius: 0 !important;
        border-bottom: none;
    }


    .login_colss {
        width: 300px !important;
    }


    .login_colsss2 {
        height: 100vh!important;
    }

    
    .text_col {
        color: #2770c4;
        font-size: 18px!important;
        font-weight: 700;
    }

    .fertrgyt {
        font-size: 18px!important;
        color: #2770c4;
        font-weight: 700;
    }

    .fthyiop {
        height: 306px !important;
    }

    #rswps-form {
        width: 260px !important;
    }
}

.remodeling_col {
    height: 84vh;
}

@media screen and (max-width: 980px) {
    .remodeling_col {
        height: 84vh;
    }
}


.button_cols {
    background-color: #2770c4 !important;
    color: #fff;
    padding: 8px 12px 7px 14px;
    font-size: 15px;
    font-weight: 700;
}

.form-control::placeholder {
    font-size: 14px !important;
}

.font_family {
    font-size: 14px;
}


.password_col {
    position: relative;
}

.password_col i {
    position: absolute;
    bottom: 0;
    top: 0;
    transform: translate(224px, 12px);
}

.fa-envelopes {
    font-size: 30px;
    color: #1974db;
}

.fgrtps {
    font-size: 14px;
    font-weight: 700;
}

.abc {
    font-size: 14px;
}

.remodeling_colsp {
    height: 80vh;
}

.gfrtyop {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-items: center;
    justify-content: flex-start !important;
}

.payment_cols {
    color: #fff !important;
    background-color: #ffc107 !important;
    padding: 7px 0px 4px 13px !important;
    width: 121px !important;
    border-radius: 0 !important;
}

.payment_cols h5 {
    font-size: 14px;
    font-weight: 600 !important;
    color: #fff !important;
}


#rswps-form {
    width: 405px;
}

.Home_container__PiYyz {
    display: grid;
    justify-content: center;
    align-content: center;

}

/* .frtyuop {
    height: 80vh !important;
} */

.previous_col {
    font-size: 13px;
    border: 1px solid darkgray;
    padding: 0px 6px 0px 6px;
}

.next_col {
    font-size: 13px;
    border: 1px solid darkgray;
    padding: 0px 6px 0px 6px;
}

.pages_col {
    font-size: 14px
}


.table_col {
    font-size: 14px;
    font-weight: 300;
}

tbody tr td {
    font-size: 14px;
    font-weight: 300;
}

.btn_grtyui {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    padding: 4px 12px 4px 12px !important;
}


.icons_cols i {
    font-size: 20px;
    color: #1f9e0b;
    background: #cbc5c5;
    padding: 8px 12px 6px 12px;
    border-radius: 4px;
}

.span_col {
    font-size: 15px;
    font-weight: 200;
}

.complaint_cols {
    background: #fff;
    padding: 16px;
    box-shadow: 0px 4px 10px 0px darkgrey;
}

.sdert_cols {
    background: #ca8e2121;
    padding: 8px;
}


.sert_text{
    font-size: 18px;
    color: #000;
    font-weight: 500;
}



.dfrtyu_col{
    overflow: auto;
}

.nav-pills {
    padding: 7px 0 4px 19px !important;
}

/* .nav-item{
    margin: 0px 133px 0px 0px;
} */

.image_colss .imgeses{
    height: 147px;
    object-fit: contain;
    object-position: center;
    width: 169px;
 }

.imges_collls{
    width: 319px;
    object-fit: contain;
    object-position: center;
    height: 260px;
 }

 .handcursor{
    cursor: pointer;
 }


.table_collsss{
    overflow: auto;
    width: 100%;
}

.table_width{
    overflow: auto;
    width: 100%;
}

.dfrtyuio{
    width: 632px;
    overflow: auto;
}

.dfrtyuyo{
    width: 585px;
    overflow: auto;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination a {
    margin: 0 5px;
    padding: 8px 16px;
    border: 1px solid #ddd;
    text-decoration: none;
    color: #007bff;
  }
  
  .pagination a.active {
    background-color: #007bff;
    color: white;
  }
  
  .pagination a:hover {
    background-color: #ddd;
  }
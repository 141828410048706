.navbar{
    box-shadow: 0px 0px 8px 1px darkgrey;
    padding: 8px;
    background-color: #fff;
}

.image img{
    width: 60px;
    object-fit: cover;
    object-position: center;
    height: 60px;
}

.navbar-dark .navbar-nav .nav-link {
    color: #000;
}

.navbar-toggler:focus {
    box-shadow: none;
    outline: none;
}

.navbar-toggler{
    border: 0;
    font-size: 16px;
    margin-right: 0px!important;
}
.navbar-brand{
    padding: 0;
    margin: 0;
}
.offcanvas-backdrop{
    background-color: rgb(245 244 244 / 8%);
}
.offcanvas-start {
    top: 45px;
    left: 0;
    width: 265px;
    transition: 1s;
}
.link-col{
    background: #2bb186;
    color: #fff;
}
.admin-col{
    padding: 3px 8px 2px 5px; 
    color: #fff;
}
.nav-link{
    color: #000!important;
    font-weight: 500;
    font-size: 14px;
    padding: 0px;
}
.offcanvas-body{
    padding: 0;
}


.reports{
    font-size: 26px;
    padding: 0;
    margin: 0;
    font-weight: 500;
}

.fgrtio li a {
    text-transform: capitalize;
    padding: 0!important;
    color: #2770c4;
    font-size: 15px;
}


.fgrtio li .fgrtp{
    padding: 8px 14px !important;
    text-transform: capitalize;
    color: #2770c4;
    font-size: 15px;
}

.fgrtio li .fgrtp:hover{
    padding: 8px 14px !important;
    text-transform: capitalize;
    color: #2770c4;
    font-size: 15px;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  background-color: #e2dddd;
  justify-content: space-around;
  top: 0;
  width: 100%;
  padding: 1em 0;
  box-shadow: none!important;
}

.navbar ul {
  display: flex;
  list-style-type: none;
  gap: 2em;
}

.navbar ul a {
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    color: rgb(21 22 22);
    transition: background 0.5s ease;
    font-weight: 500;
    font-weight: 700;
    color: #4c4747;
}

.logo {
  font: 20px Arial;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: rgb(4 47 46);
}

.navbar ul a:hover {
    border-bottom: 2px solid #2770c4;
    padding: 0;
}

.navbar ul a.active {
    border-bottom: 2px solid #2770c4;
    padding: 0;
}


.navbar-nav ul{
    margin: 0!important;
}

.navbar ul .btn-warning:hover{
    border-bottom: none!important;
    padding: 5px 10px;

}

.fgrtio .complaint_col:hover{
    border-bottom: 2px solid #2770c4;
    padding: 0!important;
}

.fgrtio .complaint_col.active{
    border-bottom: 2px solid #2770c4;
    padding: 0;
}

.complaint_col{
    font-size: 20px!important;
}

.navbar-toggler{
    margin-left: 26px!important;
}

.dfertyuio{
    line-height: 16px!important;
    text-align: center;
}

.fgrtp{
    background-color: #2770c4!important;
}

p{
    font-size: 15px;
}

label{
    font-size: 15px;
}

.fgfrtyi {
    font-size: 17px;
}




@media screen and (max-width: 448px){
    .reports {
        font-size: 22px;
        padding: 0;
        margin: 0;
        font-weight: 500;
    }
}

@media screen and (max-width: 320px){

    .reports {
        font-size: 16px !important;
        padding: 0;
        margin: 0;
        margin-left: 0px;
        font-weight: 500;
      }
}
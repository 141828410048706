.list .btn{
    background: #117605;
    color: #fff;
    padding: 2px 8px 2px 8px;
}
.list .btn:hover{
    color: #fff;
}
.list .btn:focus{
    box-shadow: none;
    outline: 0;
    border-color: #17a107;
}
.home h6{
    font-size: 14px;
}

.list h6{
    color: rgb(56 56 56);
}
.home_col{
    background: #fff;
    border-radius: 4px;
    padding: 0px 0px 40px 0px;
    box-shadow: 0px 0px 12px 0px darkgrey;
}
.list{
    padding: 0px 12px 0px 15px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #cdc9c9;
}
.input_col input{
    border: 1px solid darkgray;
}
.input_col input:focus{
    box-shadow: darkgray;
    outline: none;
}
.dropdown .btn{
    background-color: #fff;
    border: 1px solid #cdc9c9;
    color: #4c4c4c;
}
.filter{
    padding: 0px 14px 0px 14px;
    border-bottom: 1px solid #cdc9c9;
}
.dropdown span{
    width: 128px;
}
.dropdown .form-select{
    width: 35%!important;
}
.doctor_detail{
    padding: 10px 14px 6px 14px;
    border-bottom: 1px solid #cdc9c9;
    background-color: #f0f7f5;
}
.detail_input{
    padding: 0px 14px 0px 14px;
    border-bottom: 1px solid #cdc9c9;
    background-color: #f0f7f5;
}
.detail_name{
    padding: 10px 14px 6px 14px;
    border-bottom: 1px solid #cdc9c9;
}
.detail_name:hover{
    background-color: #e5e2e2;
}
.doctor_detail h6{
    color: rgb(56 56 56);
}
.select{
    width: 100%;
}
.detail_col{
    padding: 0px 24px 0px 24px;
}
.address input{
    background-color: #c7c6c6ad;
}
.search-submit{
    background: orange;
    border: 0;
    color: #fff;
    font-weight: 700;
}

@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .wrapData{
    margin: 24px 0px 24px 0px;
    font-size:14px;
    text-align: center;
  }
  
  .gfdsh{
    color: #2770c4!important;
    text-decoration: underline;
}
.css-rs23t5 {
    background-color: #8f8f8f;
    border-radius: 2px;
    display: inline-block;
    font-size: 0.85rem;
    margin: 4px;
    padding: 4px 6px;
}
.css-b62m3t-container{
    width: 100%;
}


h1, h2, h3, h4, h5, h6{
    font-family: 'Inter', sans-serif;
}

span{
    font-family: 'Inter', sans-serif;
}

.form-label{
    font-family: 'Inter', sans-serif;
}

p, small{
    font-family: 'Inter', sans-serif;
}

a{
    font-family: 'Inter', sans-serif;
}

.button{
    font-family: 'Inter', sans-serif;
}

option{
    font-family: 'Inter', sans-serif;
}

.home-search {
    padding: 7px;
    font-size: 14px;
    width: 185px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 185px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: absolute;
    background-color: #fff;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    top: 55px;
    left: 58px;
  }

  .suggestions-list-cat {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 185px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: absolute;
    background-color: #fff;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    top: 55px;
    left: 90px;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .selected-item {
    margin-top: 20px;
  }